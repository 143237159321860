.App {
    text-align: center;
}

body {
    background-color: #C0C0C0;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/*                           OD TUKA E MOJ CSS
 ####################################################################################*/
.likiclass {
}

.likiclass:hover {
    transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.likiclass:hover .showme {
    visibility: visible;
}

.showme {
    visibility: hidden;
}

.rootclass {
    max-width: 345px;
}

.mediaclass {
    height: 140px;
}

.imgcenter {
    text-align: center;
}

/*   GRADIENT BUTTON     &&          ZA INPUT NUMBER*/

.gradient-button-3 {
    background-image: linear-gradient(to right, #7474BF 0%, #348AC7 51%, #7474BF 100%)
}

.gradient-button-3:hover {
    background-position: right center;
}

.gradient-button {
    margin: 10px;
    font-family: "Arial Black", Gadget, sans-serif;
    font-size: 20px;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: #FFF;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    width: 200px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    cursor: pointer;
    display: inline-block;
    border-radius: 25px;
}

.gradient-button:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    margin: 8px 10px 12px;
}

/*GRADIENT BUTTON END*/


.number-input input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

.number-input input[type=number]::-webkit-inner-spin-button,
.number-input input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.number-input {
    margin-bottom: 3rem;
}

.number-input button {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    position: relative;
}

.number-input button:before,
.number-input button:after {
    display: inline-block;
    position: absolute;
    content: '';
    height: 2px;
    transform: translate(-50%, -50%);
}

.number-input button.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.number-input input[type=number] {
    text-align: center;
}

.number-input.number-input {
    border: 1px solid #ced4da;
    /*width: 10rem;*/
    width: auto;
    border-radius: .25rem;
}

.number-input.number-input button {
    /*width: 2.6rem;*/
    /*height: .7rem;*/
}

.number-input.number-input button.minus {
    /*padding-left: 10px;*/
}

.number-input.number-input button:before,
.number-input.number-input button:after {
    width: .7rem;
    background-color: #495057;
}

.number-input.number-input input[type=number] {
    max-width: 3.2rem;
    padding: .5rem;
    /*border: 1px solid #ced4da;*/
    /*border-width: 0 1px;*/
    font-size: 1rem;
    height: 2rem;
    color: #495057;
}


/*           FLOATING ACTION BUTTON*/
.pmd-card-actions .btn.pmd-btn-fab {
    padding: 0;
}

.pmd-ripple-effect {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
}

.btn.pmd-btn-fab {
    padding: 0;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    min-width: 56px;
}

.btn.pmd-btn-fab span,
.btn.pmd-btn-fab i {
    line-height: unset;
}

.btn.pmd-btn-fab.btn-lg {
    width: 78px;
    height: 78px;
    min-width: 78px;
}

.btn.pmd-btn-fab.btn-lg span,
.btn.pmd-btn-fab.btn-lg i {
    line-height: 78px;
}

.btn.pmd-btn-fab.btn-sm {
    width: 40px;
    height: 40px;
    min-width: 40px;
}

.btn.pmd-btn-fab.btn-sm span,
.btn.pmd-btn-fab.btn-sm i {
    line-height: 40px;
}

.btn.pmd-btn-fab.btn-xs {
    width: 30px;
    height: 30px;
    min-width: 30px;
}

.btn.pmd-btn-fab.btn-xs span,
.btn.pmd-btn-fab.btn-xs i {
    line-height: 30px;
}

.material-icons.media-left {
    padding-right: 32px;
    vertical-align: top;
    display: table-cell;
}

.material-icons.media-right {
    padding-left: 32px;
    vertical-align: top;
    display: table-cell;
}

.material-icons.media-middle {
    vertical-align: middle;
    display: table-cell;
}

.material-icons.pmd-sm, .material-icons.md-24 {
    font-size: 24px;
}

.btn-dark-green {
    color: #fff;
    background-color: #388e3c !important;
}

.linkColor {
    color: white !important;
}

.linkMargin {
    margin-left: 10px !important;
}

.menu-bar-left {
    margin-left: 8% !important;
    overflow-y: visible;
}

body {
    overflow-y: visible !important;
}

.firebaseui_container {
    margin-top: 0 !important;
    height: 100px !important;
}

.page-item.active {
    background-color: #007bff !important;
}
